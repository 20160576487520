import React, { useState } from 'react';
import styled from 'styled-components';

import { useAuthContext } from '@providers/AuthProvider';

import { Input } from '@components/form-elements/Input';
import { Button } from '@components/form-elements/buttons/Button';

import { OrganizationImage } from './OrganizationImage';
import { TOrganization } from 'app/types/entities/TOrganization';
import { OrganizationsService } from '../../../../API';
import { useAPI } from '@hooks/useAPI';

const Wrapper = styled.div`
  padding: 16px 0 20px;
  position: relative;
  display: flex;
  gap: 30px;

  .whiteInput {
    input {
      background: white !important;
    }
  }
`;

const LeftSide = styled.div`
  width: 230px;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #878787;
  flex: 0 0 auto;

  h3 {
    margin: 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #858dbd;
    padding: 0 0 10px;
  }
`;

const RightSide = styled.div``;

const RightSideColumns = styled.div`
  display: flex;
  gap: 10px;
`;

const InputBlockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px 0;
  width: 270px;

  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #858dbd;
  }
`;

const InputWrapper = styled.div`
  margin-right: 30px;
`;

const ErrorMessage = styled.div`
  color: #ff2f2f;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  padding: 40px 0 0;
  text-align: center;
`;

type OrganizationNameForm = {
  name: string;
  slug: string;
};

type TProps = {
  organization: TOrganization;
  loading: boolean;
  setLoading: (value: boolean) => void;
};

export const NameSection = ({ organization, setLoading, loading }: TProps) => {
  const { call } = useAPI();
  const [error, setError] = useState<string | null>();

  const { updateMe } = useAuthContext();

  const [form, setForm] = useState<OrganizationNameForm>({
    name: organization.name,
    slug: organization.slug,
  });

  const submit = async () => {
    setError(null);
    setLoading(true);
    await call(
      OrganizationsService.update({
        oid: organization.id,
        requestBody: {
          name: form.name,
          slug: form.slug,
        },
      }),
    );
    organization.name = form.name;
    organization.slug = form.slug;

    await updateMe();
    setLoading(false);
  };

  return (
    <div>
      <Wrapper>
        <LeftSide>
          <h3>General</h3>
          <p>Update the icon and name for your organization.</p>
        </LeftSide>

        <RightSide>
          <OrganizationImage org={organization} />
          <RightSideColumns>
            <InputBlockWrapper>
              <InputWrapper>
                <label htmlFor="name">Organization name</label>
                <Input
                  className="whiteInput"
                  disabled={loading}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setForm({
                      ...form,
                      name: e.target.value,
                    })
                  }
                  name="name"
                  value={form.name}
                  label="Organization name"
                />
              </InputWrapper>

              <InputWrapper>
                <label htmlFor="slug">Organization URL</label>
                <Input
                  className="whiteInput"
                  disabled={true}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setForm({
                      ...form,
                      slug: e.target.value,
                    })
                  }
                  name="slug"
                  value={form.slug}
                  label="Organization URL"
                />
              </InputWrapper>
            </InputBlockWrapper>
          </RightSideColumns>
        </RightSide>
      </Wrapper>
      <div className="grid place-items-end">
        <div className="w-36">
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <Button className="my-2" disabled={loading || !form.name} variant="bold" onClick={submit}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};
