import styled from 'styled-components';

export const ModalWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  text-align: center;
  width: 80vw;
  height: 80vh;
  max-width: 1000px;
  padding-bottom: 70px;

  & .content {
    overflow: hidden;
    height: calc(100% - 50px);
  }

  .buttonContainer {
    width: 300px;
    display: flex;
    grid-gap: 10px;
    padding: 20px 0 0;
    margin: 0 auto;
  }
`;
