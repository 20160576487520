import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';

import { routes } from '@routes';

import { useAuthContext } from '@providers/AuthProvider';
import { Restricted } from '@providers/PermissionProvider/Restricted';

import { EPermission } from 'app/types/enums/EPermission';
import { Header } from '@components/Header';
import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';

import { WorkspaceTable } from './WorkspaceTable';
import { TeamsList } from './TeamsList';
import { NameSection } from './NameSection/NameSection';
import { NameSectionViewOnly } from './NameSection/NameSectionViewOnly';
import { BillingAccountList } from './BillingAccountList';
import { RepositoriesTable } from './RepositoriesTable';
import { ApiKeysList } from './ApiKeysList';

const Content = styled.main`
  padding: 8px 30px 70px;
  min-height: calc(100vh - 120px);
`;

export const OrganizationDetails = () => {
  const { me, organization } = useAuthContext();
  const { orgSlug } = useParams<{ orgSlug: string }>();
  const navigate = useNavigate();

  if (!organization || organization.slug !== orgSlug) {
    navigate(routes.dashboard.make(me.organizations[0].slug));
    return <></>;
  }

  const [nameLoading, setNameLoading] = useState(false);
  const [apiKeysLoading, setApiKeysLoading] = useState(!!organization?.permissions?.manageOrganization);
  const [workspacesLoading, setWorkspacesLoading] = useState(!!organization?.permissions?.manageOrganization);
  const [repositoriesLoading, setRepositoriesLoading] = useState(!!organization?.permissions?.viewRepositories);
  const [teamsLoading, setTeamsLoading] = useState(!!organization?.permissions?.manageMembers);
  const [billingLoading, setBillingLoading] = useState(false);

  const isLoading =
    nameLoading || workspacesLoading || repositoriesLoading || teamsLoading || billingLoading || apiKeysLoading;

  return (
    <>
      <Helmet>
        <title>{organization.name} - AutoScript</title>
      </Helmet>
      <Header title={organization.name} />

      <LinearProgressLoader active={isLoading} />
      <Content>
        <div>
          <Restricted
            to={EPermission.manageOrganization}
            fallback={<NameSectionViewOnly organization={organization} />}
          >
            <NameSection organization={organization} loading={nameLoading} setLoading={setNameLoading} />
          </Restricted>

          <Restricted to={EPermission.manageWorkspaces}>
            <WorkspaceTable org={organization} loading={workspacesLoading} setLoading={setWorkspacesLoading} />
          </Restricted>

          <Restricted to={EPermission.viewRepositories}>
            <RepositoriesTable org={organization} loading={repositoriesLoading} setLoading={setRepositoriesLoading} />
          </Restricted>

          <Restricted to={EPermission.manageMembers}>
            <TeamsList organization={organization} loading={teamsLoading} setLoading={setTeamsLoading} />
          </Restricted>

          <Restricted to={EPermission.viewBilling}>
            <BillingAccountList organization={organization} loading={billingLoading} setLoading={setBillingLoading} />
          </Restricted>

          <Restricted to={EPermission.manageOrganization}>
            <ApiKeysList org={organization} loading={apiKeysLoading} setLoading={setApiKeysLoading} />
          </Restricted>
        </div>
      </Content>
    </>
  );
};
