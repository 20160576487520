export const validatePassword = (password: string, passwordConfirmation: string) => {
  if (password && passwordConfirmation && password !== passwordConfirmation) {
    return 'err.password.not_matches';
  } else if (password?.length < 8) {
    return 'err.password.to_small';
  } else if (password?.length > 50) {
    return 'err.password.to_large';
  } else if (password?.search(/\d/) === -1) {
    return 'err.password.no_num';
  } else if (password?.search(/[a-zA-Z]/) === -1) {
    return 'err.password.no_letter';
  } else if (password?.search(/[A-Z]/) === -1) {
    return 'err.password.no_uppercase';
  } else if (password?.search(/[a-z]/) === -1) {
    return 'err.password.no_lowercase';
  } else if (password?.search(/[@!#$%^&*()_]/) === -1) {
    return 'err.password.no_symbol';
  }
  return '';
};
