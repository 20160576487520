import styled from 'styled-components';
import { useAuthContext } from '@providers/AuthProvider';
import { TSubscriptionProduct } from 'app/types/entities/TSubscriptionProduct';

const Wrapper = styled.div`
  width: 230px;
  min-height: 220px;
  background: #ffffff;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;

  .as-one {
    color: #858dbd;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-transform: capitalize;
  }

  .content {
    padding: 20px 20px 10px;
  }

  .description {
    padding: 10px 0 0;
    color: #858dbd;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.42px;

    &.red {
      font-weight: 500;
      color: #ff2f2f;
    }

    &.no-padding {
      padding: 0;
    }
  }

  .buttonContainer {
    height: 40px;
    width: calc(100% - 40px);
    margin: 0 20px;
    display: block;
    bottom: 0;
    position: absolute;
    border-top: 1px solid #d5ddf2;

    button {
      border-radius: 10px;
      padding: 0;
      height: 36px;
      width: 100%;
      color: #7cb1fe;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

type TProps = {
  onButtonClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  plan: TSubscriptionProduct;
  className?: string;
};

export const PaymentFailedCard = ({ onButtonClick, className = '' }: TProps) => {
  const { organization } = useAuthContext();
  return (
    <Wrapper className={className}>
      <div className="content">
        <h2 className="as-one">Subscription</h2>
        <p className="description red">Payment has failed!</p>
        <p className="description no-padding">Currently plan is on hold, check your balance or payment method.</p>
      </div>

      {organization?.permissions?.manageLicenses ? (
        <div className="buttonContainer">
          <button onClick={onButtonClick}>Edit Subscription</button>
        </div>
      ) : null}
    </Wrapper>
  );
};
