import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { routes } from '@routes';

import { useModalContext } from '@providers/ModalProvider';
import { getHourPrice } from '@helpers/getHourPrice';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';

import { TBillingAccount } from 'app/types/entities/TBillingAccount';
import { TSubscription } from 'app/types/entities/TSubscription';
import { TSubscriptionProduct } from 'app/types/entities/TSubscriptionProduct';

import { Button } from '@components/form-elements/buttons/Button';
import { Dots } from '@components/icons/Dots';
import { DropDown } from '@components/form-elements/DropDown';
import { useAuthContext } from '@providers/AuthProvider';

import { formatRate } from '@helpers/formatRate';
import { formatDateMsDsY } from '@helpers/formatDateMsDsY';

const Wrapper = styled.div`
  width: auto;
  min-width: 230px;
  min-height: 220px;
  background: #ffffff;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;

  .content {
    padding: 20px 20px 60px;
  }

  .title {
    color: #858dbd;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }

  .plan-name {
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
    color: #7cb1fe;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .price {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: left;
    color: #858dbd;
    .divider {
      height: 41px;
      border-right: 1px solid #858dbd;
      flex: 0 0 auto;
    }
  }

  h3 {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    white-space: nowrap;
  }

  .active {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    padding: 8px 0 0;
  }

  .buttonContainer {
    height: 40px;
    width: calc(100% - 40px);
    margin: 0 20px;
    display: block;
    bottom: 0;
    position: absolute;
    border-top: 1px solid #d5ddf2;

    button {
      border-radius: 10px;
      padding: 0;
      height: 36px;
      width: 100%;
    }
  }
`;

const StyledButton = styled(Button)`
  box-sizing: border-box;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
`;

const DotsButton = styled.div`
  position: absolute;
  top: 20px;
  right: 10px;
  cursor: pointer;

  :hover {
    & svg {
      circle {
        fill: #858dbd;
      }
    }
  }
`;

type TProps = {
  className?: string;
  onButtonClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  reload: () => void;
  plan: TSubscriptionProduct;
  subscription: TSubscription;
  billingAccount: TBillingAccount;
};

export const ActiveCard = ({ className = '', plan, subscription, billingAccount, onButtonClick, reload }: TProps) => {
  const { openModal } = useModalContext();
  const navigate = useNavigate();
  const { organization } = useAuthContext();
  // const licensesCount = subscription?.licenses?.length || 1;

  const [showDropDown, setShowDropDown] = useState(false);

  const planName = plan?.name.replace('AutoScriptOne ', '').replace('RealTime ', '');
  const price = getHourPrice(plan?.capacityInSeconds, Number(plan?.price), true);
  const billingConfiguration = plan.DefaultBillingConfiguration;

  const unassignedCount = subscription?.licenses.filter((lic) => lic.assigneeId === null).length;
  const assignedCount = subscription?.licenses.filter((lic) => lic.assigneeId !== null).length;

  if (!billingConfiguration) {
    return null;
  }

  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (onButtonClick) {
      onButtonClick(e);
    }
  };

  const dropDownOptions: {
    title: string;
    onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  }[] = [];

  if (organization?.permissions?.viewLicenses) {
    dropDownOptions.push({
      title: 'Details',
      onClick: (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        navigate(routes.autoScriptOneSubscriptionDetails.make(organization?.slug ?? '', subscription.id));
      },
    });
  }

  if (organization?.permissions?.manageLicenses) {
    dropDownOptions.push({
      title: 'Cancel',
      onClick: (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        openModal(ModalNamesEnum.ConfirmSubscriptionCancellation, {
          billingAccountId: billingAccount?.id,
          subscriptionId: subscription.id,
          planName: plan.name,
          onSuccess: () => reload(),
        });
      },
    });
  }

  const renderOptions = () => {
    return (
      <>
        <DotsButton
          onClick={(e) => {
            e.stopPropagation();
            setShowDropDown(true);
          }}
        >
          <Dots />
        </DotsButton>
        {showDropDown ? <DropDown close={() => setShowDropDown(false)} options={dropDownOptions} /> : null}
      </>
    );
  };

  return (
    <Wrapper className={className}>
      <div className="content">
        <h2 className="title">AutoScript One</h2>
        <h2 className="plan-name">{planName}</h2>
        {dropDownOptions.length > 0 && renderOptions()}

        <div className="price flex gap-3 pt-1 pb-2">
          <div>
            <div>Monthly</div>
            <div>{price}/seat</div>
          </div>
          <div className="divider"></div>
          <div>
            <div>Active</div>
            <div>
              {assignedCount}/{assignedCount + unassignedCount}
            </div>
          </div>
        </div>

        {billingConfiguration.postProcessingRateType !== 'DISALLOWED' &&
        billingConfiguration.postProcessingRateType !== 'TIER_RATE' ? (
          <h3>
            {formatRate(
              billingConfiguration.postProcessingRate,
              billingConfiguration.postProcessingRateType,
              'Post ASR',
              false,
            )}
          </h3>
        ) : null}
        {billingConfiguration.realTimeRateType !== 'DISALLOWED' ? (
          <h3>
            {formatRate(
              billingConfiguration.realTimeRate,
              billingConfiguration.realTimeRateType,
              'Real-time streaming',
              false,
            )}
          </h3>
        ) : null}
        {billingConfiguration.partialUnlockRateType !== 'DISALLOWED' ? (
          <h3>
            {formatRate(
              billingConfiguration.partialUnlockRate,
              billingConfiguration.partialUnlockRateType,
              'Reporter unlock',
              false,
            )}
          </h3>
        ) : null}
        {billingConfiguration.fullUnlockRateType !== 'DISALLOWED' ? (
          <h3>
            {formatRate(
              billingConfiguration.fullUnlockRate,
              billingConfiguration.fullUnlockRateType,
              'Full unlock',
              false,
            )}
          </h3>
        ) : null}
        {subscription.billingCycleAnchor ? (
          <h3>Next payment: {formatDateMsDsY(subscription.billingCycleAnchor)}</h3>
        ) : null}
        {subscription.freeTrialEndDate ? (
          <h3>Trial expiration: {formatDateMsDsY(subscription.freeTrialEndDate)}</h3>
        ) : null}
      </div>

      {onButtonClick && organization?.permissions?.manageLicenses ? (
        <div className="buttonContainer">
          <StyledButton size="medium" variant="text" onClick={onClick}>
            Edit Subscription
          </StyledButton>
        </div>
      ) : null}
    </Wrapper>
  );
};
