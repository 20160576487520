import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import React, { useState } from 'react';

import { routes } from '@routes';

import { useModalContext } from '@providers/ModalProvider';
import { useAuthContext } from '@providers/AuthProvider';

import { JOB_STATUSES_ENUM } from '@constants/enums/jobStatuses';
import { REPORTING_TYPE_ENUM } from '@constants/enums/reportingTypes';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { TJob } from 'app/types/entities/TJob';

import { getAmericanTime } from '@helpers/getAmericanTimezone';
import { convertDuration } from '@helpers/convertDuration';
import { formatDateMsDsYHI } from '@helpers/formatDateMsDsYHI';
import { jobIsEditable } from '@helpers/jobStatus/jobIsEditable';
import { jobIsScheduled } from '@helpers/jobStatus/jobIsScheduled';
import { jobCanBeReassigned } from '@helpers/jobStatus/jobCanBeReassigned';

import { SortableHead } from '@components/Table/SortableHead';
import { SortingDirection } from '@components/Table/SortingDirection';
import { Pencil as PencilIcon } from '@components/icons/Pencil';
import { Tooltip } from '@components/Tooltip';
import { StatusText } from '@components/Table/StatusText';
import { HistoryIcon } from '@components/icons/History';
import { Move as MoveIcon } from '@components/icons/Move';
import { ASOne as ASOneIcon } from '@components/icons/ASOne';
import { Hamburger as HamburgerIcon } from '@components/icons/Hamburger';
import { IconButton } from '@components/form-elements/buttons/IconButton';
import { LockedJob } from '@components/icons/LockedJob';
import { Button } from '@components/form-elements/buttons/Button';
import { CheckBox } from '@components/form-elements/CheckBox';

import { JobStatusActionsButton } from '../JobStatusActionsButton';
import { canShowHamburger } from '../helpers/canShowHamburger';
import { HamburgerButtons } from '../HamburgerButtons';

const StyledMoveIconButton = styled(IconButton)`
  width: 30px;
  height: 30px;
  align-self: center;

  :hover {
    background: #cadcf8;

    path {
      stroke: #fff;
      color: #fff;
    }
  }
`;

const StyledIconLink = styled.a`
  width: 30px;
  height: 30px;
  align-self: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  flex: 0 0 auto;

  :hover {
    background: #cadcf8;

    path {
      fill: #fff;
      color: #fff;
    }
  }
`;

const StyledHamburgerIcon = styled(IconButton)`
  width: 30px;
  height: 30px;
  align-self: center;

  :hover {
    background: #cadcf8;

    circle {
      fill: #fff;
      color: #fff;
    }
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  gap: 10px;
  position: relative;
  overflow: visible !important;
`;

const StatusCell = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  min-width: 80px;

  &.with-history {
    padding-right: 28px;
  }
`;

const StatusCellContent = styled.div`
  position: relative;

  p:first-child {
    font-size: 16px;
    line-height: 130%;
    margin: 10px 0 0;
  }
`;

const StatusDate = styled.p`
  color: #b4b9d9;
  margin: 0 0 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 160%;
`;

const HistoryButton = styled.div`
  margin: 0;
  width: 18px;
  height: 18px;
  cursor: pointer;

  :hover {
    path {
      fill: #858dbd;
    }
  }
`;

const JobName = styled.div`
  color: #00122d;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  margin: 10px 0 0;

  .tag {
    padding: 4px 6px;
    border-radius: 5px;
    border: 1px solid #d5def2;
    background: #f8fafb;
    color: #b4b9d9;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 14.4px */
    color: #40608f;
    background: #d5def2;
  }

  a {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #00122d;
  }
`;

const IdField = styled.p`
  margin: 0 0 3px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 160%;
  color: #b4b9d9;
`;

const TagsField = styled.p`
  display: flex;
  gap: 5px;
  margin: 0 0 10px;
  font-style: normal;
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  text-align: left;
  color: #40608f;
  flex-wrap: wrap;

  * {
    white-space: unset !important;
    overflow: visible !important;
    text-overflow: unset !important;
  }

  &:empty {
    display: none;
  }
`;

const AssignCell = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  overflow: visible !important;

  svg {
    cursor: pointer;

    :hover {
      path {
        fill: #858dbd;
      }
    }
  }

  .assignee {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 140px;

    .assignName {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 130%;
      margin: 10px 0 0;
      text-decoration: underline;
    }

    .assignEmail {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 160%;
      align-items: center;
      justify-content: center;
      margin: 0 0 10px;
      text-decoration: none;
    }
  }
`;

const DeadlineCell = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  overflow: visible !important;

  .deadLine {
    width: 95px;
  }

  svg {
    cursor: pointer;

    :hover {
      path {
        fill: #858dbd;
      }
    }
  }
`;

const StyledLink = styled(RouterLink)`
  color: #00122d;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  text-decoration: underline;

  &:hover {
    color: #40608f;
  }
`;
const AssignLink = styled(StyledLink)`
  text-decoration: none;
`;

type TProps = {
  reload: () => void;
  orderBy: string;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  orderType: string;
  setOrderType: React.Dispatch<React.SetStateAction<string>>;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  jobs: TJob[];
  checkedJobs: string[];
  setCheckedJobs: (v: string[]) => void;
};

export const useJobsTableHead = ({
  reload,
  orderBy,
  setOrderBy,
  orderType,
  setOrderType,
  setSearch,
  jobs,
  checkedJobs,
  setCheckedJobs,
}: TProps) => {
  const { workspace, me, organization } = useAuthContext();
  const { openModal, closeModal } = useModalContext();

  const [hiddenButtonsVisible, setHiddenButtonsVisible] = useState<string | null>(null);
  const isVsAdmin = me?.roles?.ROLE_VS_ADMIN;

  const canViewAllJobsHistory = organization?.permissions?.viewJobs === 'all';
  const canManageWorkspaces = organization?.permissions?.manageWorkspaces;
  const canSubmitJobs = organization?.permissions?.submitJobs;
  const jobsAvailableToEdit = jobs.filter((job) => !job.asOneLocked);

  const checkJob = (id: string) => {
    if (checkedJobs.includes(id)) {
      setCheckedJobs(checkedJobs.filter((jobId) => jobId !== id));
    } else {
      setCheckedJobs([...checkedJobs, id]);
    }
  };

  const checkAllJobs = () => {
    if (checkedJobs.length === jobsAvailableToEdit.length) {
      setCheckedJobs([]);
    } else {
      setCheckedJobs(jobsAvailableToEdit.map((job: TJob) => job.id));
    }
  };

  const showMoveJobToWSModal = (job: TJob) => {
    openModal(ModalNamesEnum.MoveJobToWSModal, {
      onSuccess: () => {
        reload();
        closeModal();
      },
      job,
    });
  };

  const editAssignee = (job: TJob) => {
    openModal(ModalNamesEnum.AssignJobModal, {
      onSuccess: () => {
        reload();
        closeModal();
      },
      job,
    });
  };

  const editDeadline = (job: TJob) => {
    openModal(ModalNamesEnum.EditDeadline, {
      onSuccess: () => {
        reload();
        closeModal();
      },
      job,
    });
  };

  const handleShowHistory = (job: TJob) => {
    openModal(ModalNamesEnum.JobHistory, {
      jobId: job.id,
    });
  };

  const toggleSort = (field: keyof TJob) => {
    if (orderBy !== field) {
      setOrderBy(field);
      setOrderType('asc');
    } else {
      setOrderType((prevState) => (prevState === 'asc' ? 'desc' : 'asc'));
    }
  };

  const generateJobLink = (jobId: string, jobStatus: keyof typeof JOB_STATUSES_ENUM) => {
    if (jobIsEditable(jobStatus)) {
      return routes.jobEdit.make(organization?.slug ?? '', workspace?.slug ?? '', jobId);
    }

    return routes.jobDetails.make(organization?.slug ?? '', workspace?.slug ?? '', jobId);
  };

  const tableHead = [
    {
      headComponent: () => (
        <SortableHead onClick={() => toggleSort('name')}>
          Job Title
          {orderBy === 'name' && <SortingDirection reverse={orderType === 'desc'} />}
        </SortableHead>
      ),
      render: (data: TJob) => (
        <div>
          <div className="flex gap-3 items-center">
            {data.asOneLocked ? <LockedJob className="flex-shrink-0 pl-2" width="30" /> : null}
            <div>
              <JobName data-testid={`jobTitle${data.name}`}>
                {data.asOneLocked ? (
                  data.name
                ) : (
                  <div className="flex flex-row">
                    {data.meetingId ? <div className="tag mr-2"> AS ONE </div> : null}
                    <StyledLink to={generateJobLink(data.id, data.status)}>{data.name}</StyledLink>
                  </div>
                )}
              </JobName>
              <IdField>{data.id}</IdField>
              <TagsField>
                {(data.tags ?? []).map((tag, i) => {
                  return (
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setSearch(`#${tag}`);
                      }}
                      key={`${data.id}-${i}`}
                    >
                      #{tag}
                    </a>
                  );
                })}
              </TagsField>
            </div>
          </div>
        </div>
      ),
    },
    {
      render: (data: TJob) => (
        <DeadlineCell>
          {!data.deadline && <p className="deadLine"></p>}
          {data.deadline && <p className="deadLine">{getAmericanTime(data.deadline, false)}</p>}
          {organization?.permissions?.submitJobs && <PencilIcon onClick={() => editDeadline(data)} />}
        </DeadlineCell>
      ),
      headComponent: () => (
        <SortableHead style={{ width: '1%' }} onClick={() => toggleSort('deadline')}>
          Due
          {orderBy === 'deadline' && <SortingDirection reverse={orderType === 'desc'} />}
        </SortableHead>
      ),
    },
    {
      render: (data: TJob, index: number) => {
        const showEditAssigneeButton = organization?.permissions?.reassignJobs && jobCanBeReassigned(data.status);
        const assignee = data?.assignee || data?.draftAssignee;
        return (
          <AssignCell>
            {!assignee ? <div className="assignee"></div> : null}
            {assignee ? (
              <Tooltip key={index} content={assignee?.email || ''} placement="bottom">
                <div className="assignee">
                  <AssignLink to={routes.userDetails.make(organization?.slug ?? '', assignee?.id)}>
                    <p className="assignName">
                      {assignee?.name} {assignee?.lastname}
                    </p>
                    <p className="assignEmail">{assignee?.email || ''}</p>
                  </AssignLink>
                </div>
              </Tooltip>
            ) : null}
            {showEditAssigneeButton ? <PencilIcon onClick={() => editAssignee(data)} /> : null}
          </AssignCell>
        );
      },
      headComponent: () => <td style={{ width: '1%' }}>ASSIGNEE</td>,
    },
    {
      render: (data: TJob) => <p>{getAmericanTime(data.createdAt)}</p>,
      headComponent: () => (
        <SortableHead style={{ width: '1%' }} onClick={() => toggleSort('createdAt')}>
          Created
          {orderBy === 'createdAt' ? <SortingDirection reverse={orderType === 'desc'} /> : null}
        </SortableHead>
      ),
    },
    {
      render: (data: TJob) => (
        <p>
          {data.reportingType === REPORTING_TYPE_ENUM.REPORTING_TYPE_FTR
            ? 'To be analyzed'
            : convertDuration(parseInt((data?.duration ?? 0).toString(), 10))}
        </p>
      ),
      headComponent: () => (
        <SortableHead style={{ width: '1%' }} onClick={() => toggleSort('duration')}>
          Duration
          {orderBy === 'duration' ? <SortingDirection reverse={orderType === 'desc'} /> : null}
        </SortableHead>
      ),
    },
    {
      render: (data: TJob) => {
        const showHistory = canViewAllJobsHistory || me.id === data.assignee?.id;
        const cellClass = showHistory ? 'with-history' : '';
        return (
          <StatusCell className={cellClass}>
            <StatusCellContent>
              {data.asOneLocked && data.status === 'JOB_STATUS_PROCESSING' ? (
                <StatusText
                  testid={`jobStatus${data.name}`}
                  status={data.pipelineStatus === 'assembly_submitter_completed' ? 'Locked' : 'Preprocessing'}
                />
              ) : data.asOneLocked && data.status === 'JOB_STATUS_PROCESSING' ? (
                <StatusText status="Locked" />
              ) : (
                <StatusText testid={`jobStatus${data.name}`} status={JOB_STATUSES_ENUM[data.status]} />
              )}
              <StatusDate>{formatDateMsDsYHI(data.statusChangeDate ?? '')}</StatusDate>
            </StatusCellContent>
            {showHistory ? (
              <HistoryButton onClick={() => handleShowHistory(data)}>
                <HistoryIcon />
              </HistoryButton>
            ) : null}
          </StatusCell>
        );
      },
      headComponent: () => (
        <SortableHead style={{ width: '1%' }} onClick={() => toggleSort('status')}>
          Status
          {orderBy === 'status' ? <SortingDirection reverse={orderType === 'desc'} /> : null}
        </SortableHead>
      ),
    },
    {
      render: (data: TJob) => <JobStatusActionsButton job={data} reload={reload} />,
      headComponent: () => <td style={{ width: '1%' }} />,
    },

    {
      render: (data: TJob) => {
        const showASOneButton = !data.asOneLocked && jobIsScheduled(data.status);

        const getASOneLinkPrefix = () => {
          return `${window.location.origin}/as-one/job/`;
        };

        const unlockJob = (id: string, name: string) => {
          openModal(ModalNamesEnum.UnlockJob, {
            onCancel: closeModal,
            onSuccess: () => {
              closeModal();
              reload();
            },
            isSubmitting: false,
            id,
            name,
          });
        };

        return (
          <IconsWrapper>
            {data.asOneLocked &&
            data.pipelineStatus === 'assembly_submitter_completed' &&
            organization?.billingAccount?.billingConfiguration.fullUnlockRateType !== 'DISALLOWED' ? (
              <Button className="unlock-button" onClick={() => unlockJob(data.id, data.name)} variant="outlined">
                Unlock now
              </Button>
            ) : null}
            {!data.asOneLocked && canSubmitJobs ? (
              <StyledMoveIconButton title="Move to another Workspace" onClick={() => showMoveJobToWSModal(data)}>
                <MoveIcon />
              </StyledMoveIconButton>
            ) : null}
            {showASOneButton ? (
              <StyledIconLink
                title="Open in AutoScriptOne"
                href={`${getASOneLinkPrefix()}${data.meetingId}`}
                aria-disabled={false}
              >
                <ASOneIcon />
              </StyledIconLink>
            ) : null}
            {!data.asOneLocked && canShowHamburger(data, isVsAdmin, canManageWorkspaces, organization, me) ? (
              <>
                <StyledHamburgerIcon
                  title="Open additional actions"
                  onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                    e.preventDefault();
                    setHiddenButtonsVisible(data.id);
                  }}
                >
                  <HamburgerIcon />
                </StyledHamburgerIcon>

                {hiddenButtonsVisible === data.id ? (
                  <HamburgerButtons job={data} reload={reload} setHiddenButtonsVisible={setHiddenButtonsVisible} />
                ) : null}
              </>
            ) : null}
          </IconsWrapper>
        );
      },
      renderStyles: { width: '1%' },
      headComponent: () => <td style={{ width: '1%' }} />,
    },
  ];

  if (organization?.permissions?.editJobs || organization?.permissions?.submitJobs) {
    tableHead.unshift({
      headComponent: () => (
        <td style={{ width: '1%' }}>
          <CheckBox checked={checkedJobs.length === jobsAvailableToEdit.length} onChange={() => checkAllJobs()} />
        </td>
      ),
      render: (data: TJob) => (
        <div>
          <div className="flex gap-3 items-center">
            <CheckBox
              disabled={data.asOneLocked}
              checked={checkedJobs.includes(data.id)}
              onChange={() => checkJob(data.id)}
            />
          </div>
        </div>
      ),
    });
  }

  return { tableHead };
};
