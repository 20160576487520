import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 230px;
  height: 135px;
  background: transparent;
  border-radius: 10px;
  border: 1px solid #d5def2;
  position: relative;
  display: flex;
  flex-direction: column;

  .title {
    color: #858dbd;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-transform: capitalize;
  }

  .content {
    padding: 20px 20px 4px;
  }

  .info {
    padding: 0 20px;

    .rate {
      color: #40608f;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 27px */
    }
  }

  .description {
    padding: 8px 20px 0;
    color: #878787;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
  }

  .dots-button {
    position: absolute;
    top: 20px;
    right: 10px;
    cursor: pointer;

    :hover {
      & svg {
        circle {
          fill: #858dbd;
        }
      }
    }
  }
`;

export const SwitchWrapper = styled.span`
  display: block;
  margin: 0;
  text-align: center;
`;
